import React from 'react';
import styles from './Skills.module.scss';

interface SkillsProps {
    
}

const Skills: React.FC<SkillsProps> = ({}) => {
    return <div className={`${styles.Container} SubpageContainer`} id={'Skills'}>
        
    </div>
}

export default Skills;