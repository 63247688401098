import React from 'react';
import styles from './TopContent.module.scss';
import AboutMe from "./AboutMe/AboutMe";
import Skills from "./Skills/Skills";
import Projects from "./Projects/Projects";
import Contact from "./Contact/Contact";
import useStore from "../../Store/Store";
import {useTranslate} from "../../Common/Translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import Career from "./Career/Career";

interface TopContentProps {
    
}

const TopContent: React.FC<TopContentProps> = ({}) => {
    const {setBgState} = useStore();
    const translations = useTranslate();
    
    const onCheckOutClick = () => {
        document.getElementById('AboutMe')?.scrollIntoView({behavior: "smooth"});
        setBgState(true);
    }
    
    return <div className={styles.Wrapper}>
        <button style={{position: "fixed", top: '70%', zIndex: 2, left: 0, right: 0, margin: 'auto'}} className={'FancyButton'} onClick={onCheckOutClick}>
            <FontAwesomeIcon icon={faArrowDown} /> {translations.get('Check out')}
        </button>
        <div className={styles.Container}>
            {/*'About me': 'About me',*/}
            {/*'Skills': 'Skills',*/}
            {/*'Projects': 'Projects',*/}
            {/*'Contact': 'Contact',*/}
            <AboutMe />
            <Skills />
            <Career />
            <Projects />
            <Contact />
        </div>
    </div>
}

export default TopContent;