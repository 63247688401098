import React, {PropsWithChildren} from 'react';

interface TitleProps extends PropsWithChildren {
    
}

const Title: React.FC<TitleProps> = ({children}) => {
    return <div style={{fontSize: 32, fontWeight: 700, borderBottom: '3px solid #eee', width: 'fit-content', paddingLeft: 32, marginLeft: -16, paddingBottom: 4}}>
        {children}
    </div>
}

export default Title;