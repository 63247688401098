import React from 'react';
import './globals.scss';
import BackContent from "./Components/BackContent/BackContent";
import TopContent from "./Components/TopContent/TopContent";
import Navigation from "./Components/Navigation/Navigation";
import {Toaster} from "react-hot-toast";

const App: React.FC = () => {
    return <div className={`Wrapper`}>
        <Navigation />
        <div className={`Content`} id={`Content`}>
            <BackContent />
            <TopContent />
        </div>

        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            }}
        />
    </div>;
};

export default App;
