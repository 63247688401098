import React from 'react';
import styles from './Career.module.scss';
import Title from "../../../Common/Title/Title";
import {useTranslate} from "../../../Common/Translate";

interface CareerProps {
    
}

interface JobProps {
    name: string;
    company: string;
    things: string[];
    date: [string, string];
}

const Job: React.FC<JobProps> = ({name, company, things, date: [date1, date2]}) => {
    const translations = useTranslate();
    
    return <div className={styles.JobBox} key={name}>
        <div className={styles.JobLine} />
        <div className={styles.JobTop}>
            <div className={styles.JobName}>{name}</div>
            {company && <div className={styles.JobCompany}>{translations.get('at')} {company}</div>}
            {date1 && date2 && <div className={styles.JobDate}>{translations.get('from')} <span>{date1}</span> {translations.get('to')} <span>{date2}</span></div>}
        </div>
        <div className={styles.Things}>
            {things.map((thing) => <div className={styles.Thing}>- {thing}</div>)}
        </div>
    </div>
}

const Career: React.FC<CareerProps> = ({}) => {
    const translations = useTranslate();

    const jobs: JobProps[] = [
        {
            name: 'COPYWRITER',
            company: 'Topsell',
            things: [
                translations.get('Career:Things:Topsell:1'),
                translations.get('Career:Things:Topsell:2')
            ],
            date: [
                '2020-07',
                '2020-08'
            ]
        },
        {
            name: 'WEB DEVELOPER',
            company: 'Thinkcode',
            things: [
                translations.get('Career:Things:Thinkcode:1'),
                translations.get('Career:Things:Thinkcode:2'),
                translations.get('Career:Things:Thinkcode:3'),
            ],
            date: [
                '2020-10',
                '2020-11'
            ]
        },
        {
            name: 'FRONTEND DEVELOPER',
            company: 'Photon Education',
            things: [
                translations.get('Career:Things:Photon:1'),
                translations.get('Career:Things:Photon:2'),
                translations.get('Career:Things:Photon:3'),
                translations.get('Career:Things:Photon:4'),
                translations.get('Career:Things:Photon:5'),
                translations.get('Career:Things:Photon:6'),
                translations.get('Career:Things:Photon:7'),
                translations.get('Career:Things:Photon:8'),
            ],
            date: [
                '2022-07',
                translations.get('now')
            ]
        },
        {
            name: translations.get('MaybeYourTeam'),
            company: '',
            things: [],
            date: [
                '',
                ''
            ]
        },
    ];
    
    return <div className={`${styles.Container} SubpageContainer`} id={'Career'}>
        <Title>{translations.get('Career')}</Title>
        <div className={styles.CareerBox}>
            {jobs.map((props) => <Job {...props} key={props.name} />)}
            <div className={styles.Arrow} />
        </div>
    </div>
}

export default Career;