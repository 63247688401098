import React from 'react';
import styles from './Contact.module.scss';

interface ContactProps {
    
}

const Contact: React.FC<ContactProps> = ({}) => {
    return <div className={`${styles.Container} SubpageContainer`} id={'Contact'}>
        
    </div>
}

export default Contact;