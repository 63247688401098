import React, {useRef, useState} from 'react';
import styles from './Projects.module.scss';
import Title from "../../../Common/Title/Title";
import {useTranslate} from "../../../Common/Translate";
import {Point} from "../../../Types/types";

interface ProjectsProps {
    
}

interface ProjectProps {
    title: string;
    description: string;
    image: string;
    url: string;
    tags: string[];
}

const Project: React.FC<ProjectProps> = ({title, url, tags, image, description}) => {
    const projectRef = useRef<HTMLDivElement>(null);
    const handleClick = () => window.open(url, '_blank')?.focus();

    const [mousePosition, setMousePosition] = useState<Point>({x: 0, y: 0});

    const onMouseMove = (e: React.MouseEvent) => {
        const rect = projectRef.current?.getBoundingClientRect();
        if (!rect) return;
        setMousePosition({x: e.clientX - rect.x, y: e.clientY - rect.y});
    }

    const renderCircle = () => {
        return <div className={styles.Circle} style={{top: `calc(${mousePosition.y}px - calc(15vh + 15vw) / 2)`, left: `calc(${mousePosition.x}px - calc(15vh + 15vw) / 2)`}}>
            <div className={styles.CircleInner}>
                <div className={styles.CircleInner}>
                    <div className={styles.CircleInner}>
                        <div className={styles.CircleInner}>
                            <div className={styles.CircleInner}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    };

    return <div className={styles.ProjectContainer} onClick={handleClick}>
        <div className={styles.Project} onMouseMove={onMouseMove} ref={projectRef}>
            {renderCircle()}
            <div className={styles.ProjectHalf}>
                <div className={styles.ProjectImage}>
                    <img src={image} alt={title} />
                </div>
            </div>
            <div className={styles.ProjectHalf}>
                <div className={styles.ProjectRightSide}>
                    <div className={styles.ProjectTitle}>{title}</div>
                    <div className={styles.ProjectDescription}>{description}</div>
                </div>
            </div>
            <div className={styles.TagsContainer}>
                {tags.map((tag, index) => {
                    return <div className={styles.Tag} key={index}>{tag}</div>
                })}
            </div>
        </div>
    </div>
}

interface ProjectType {
    title: string;
    description: string;
    url: string;
    tags: string[];
    image: string;
}

const Projects: React.FC<ProjectsProps> = ({}) => {
    const translations = useTranslate();
    
    const projects: ProjectType[] = [
        {
            title: translations.get('SaperJS'),
            description: translations.get('Portfolio:SaperJS:Description'),
            url: '/saper',
            image: '/portfolio/saper.png',
            tags: ['HTML', 'CSS', 'Javascript']
        }, 
        {
            title: translations.get('PNG To Emote Converter'),
            description: translations.get('Portfolio:PNGToEmoteConverter:Description'),
            url: '/PNGtoEmoteConverter',
            image: '/portfolio/PNGToEmoteConverter.png',
            tags: ['HTML', 'CSS', 'Javascript']
        },
        {
            title: translations.get('Isometric Engine'),
            description: translations.get('Portfolio:IsometricGame:Description'),
            url: '/IsometricEngine',
            image: '/portfolio/IsometricEngine.png',
            tags: ['HTML', 'CSS', 'Javascript']
        },
        {
            title: translations.get('Tetris'),
            description: translations.get('Portfolio:Tetris:Description'),
            url: '/Tetris',
            image: '/portfolio/Tetris.png',
            tags: ['HTML', 'CSS', 'Javascript']
        },
        {
            title: translations.get('This Portfolio'),
            description: translations.get('Portfolio:Portfolio:Description'),
            url: '/',
            image: '/portfolio/Portfolio.png',
            tags: ['React', 'Typescript', 'HTML', 'CSS']
        },
    ];
    
    return <div className={`${styles.Container} SubpageContainer`} id={'Projects'}>
        <Title>{translations.get('Projects')}</Title>
            <div className={styles.ProjectsContainer}>
                {projects.map((props, index) => <Project {...props} key={index} />)}
            </div>
    </div>
}

export default Projects;