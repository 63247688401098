import React from 'react';
import useStore, {Languages} from "../Store/Store";

interface TranslateProps {
    name: string;
}

const Translate: React.FC<TranslateProps> = ({name}) => {
    const translate = useTranslate();
    
    return <>{translate.get(name)}</>
}

export const useTranslate = () => {
    const {translations, language} = useStore();
    
    const get = (name: string) => {
        return translations[language][name] || translations[Languages.EN][name] || name || '';
    }
    
    return {get};
}

export default Translate;