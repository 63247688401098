import React from 'react';
import styles from './Navigation.module.scss';
import {useEventListener} from "usehooks-ts";
import {useTranslate} from "../../Common/Translate";
import useStore, {Languages} from "../../Store/Store";
import Flag from 'react-world-flags';

interface NavigationProps {
    
}

const convertLang = (lang: Languages) => {
    return lang
        .replace('en', 'us');
}

const Navigation: React.FC<NavigationProps> = ({}) => {
    const translations = useTranslate();
    const {bgState, setBgState, language, setLanguage} = useStore();

    const handleScroll = (e: any) => {
        const contentElement = document.getElementById('Content');
        if (!contentElement) return;
        setTimeout(() => {
            const scrollTop = contentElement.scrollTop;
            console.log(scrollTop);
            setBgState(scrollTop >= window.innerHeight);  
        }, 200);
    };
    
    useEventListener('wheel', handleScroll);
    useEventListener('scroll', handleScroll);
    
    useEventListener('touchmove', handleScroll);
    
    function NavigationButton(title: string, onclick: string | (() => void)) {
        const idclick = () => {
            typeof onclick === 'string' && document.getElementById(onclick)?.scrollIntoView({behavior: 'smooth'});
            setBgState(true);
        }
        
        return <li onClick={typeof onclick === 'string' ? idclick : onclick}>{title}</li>
    }
    
    function scrollToTop() {
        document.getElementById('Content')?.scrollTo({top: 0, behavior: "smooth"});
        setBgState(false)
    }

    return <div className={`${styles.Container} ${!bgState ? styles.ContainerOnTop : ''}`} style={{fontSize: bgState ? 16 : 20}}>
        <ul>
                {NavigationButton(translations.get('Home'), scrollToTop)}
                {NavigationButton(translations.get('About me'), 'AboutMe')}
                {/*{NavigationButton(translations.get('Skills'), 'Skills')}*/}
                {NavigationButton(translations.get('Career'), 'Career')}
                {NavigationButton(translations.get('Projects'), 'Projects')}
        </ul>
        <div className={styles.Locales}>
            <Flag code={convertLang(language)} width={32} />
            
            <div className={styles.LocalesList}>
                {Object.values(Languages).map((lang) => {
                    if (lang === language) return null;
                    const swapLanguage = () => setLanguage(lang);
                    const convertedLang = convertLang(lang);
                    return <Flag code={convertedLang} width={32} onClick={swapLanguage} key={convertedLang} />  
                })}
            </div>
        </div>
    </div>
}

export default Navigation;