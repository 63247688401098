import React from 'react';
import styles from './Socials.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faDiscord, faFacebook, faGithub, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

import toast, { Toaster } from 'react-hot-toast';
import {useTranslate} from "../../Common/Translate";
import useStore from "../../Store/Store";

interface SocialsProps {
    
}

interface SocialButtonProps {
    url?: string;
    icon: IconProp;
    value?: string;
    className?: string;
}


const SocialButton: React.FC<SocialButtonProps> = ({icon, value, url, className}) => {
    const translations = useTranslate();
    const copiedTranslation = translations.get('Copied');

    const onClick = async () => {
        if (!value) return;
        await navigator.clipboard.writeText(value);
        toast(copiedTranslation);
    }
    
    const button = <div className={`${styles.Button} ${className ? className : ''}`} onClick={value ? onClick : undefined}>
        <FontAwesomeIcon icon={icon} />
    </div>
    
    return url ? <a href={url} target={'_blank'}>{button}</a> : button
} 

const Socials: React.FC<SocialsProps> = ({}) => {
    return <div className={styles.Container}>
        <SocialButton icon={faGithub} url={'https://github.com/Przemek19'} className={styles.GithubButton} />
        <SocialButton icon={faLinkedinIn} url={'https://www.linkedin.com/in/przemys%C5%82aw-bajkowski-54174b215/'} className={styles.LinkedinButton} />
        <SocialButton icon={faDiscord} value={'.pevo'} className={styles.DiscordButton} />
    </div>
}

export default Socials;