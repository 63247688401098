import React, {useEffect, useMemo, useState} from 'react';
import styles from './BackContent.module.scss';
import {TypeAnimation} from "react-type-animation";
import {useTranslate} from "../../Common/Translate";
import {Point} from "../../Types/types";
import {random} from "../../Common/utils";
import useBoundingRect from "../../Common/Hooks/useBoundingRect";
import useStore from "../../Store/Store";

interface BackContentProps {
    
}

interface Particle extends Point {
    size: number;
}

const Particles: React.FC = () => {
    const [particles, setParticle] = useState<Particle[]>([]);
    const rect = useBoundingRect();

    const renderParticle = (particle: Particle, index: number) => {
        const {x, y, size} = particle;
        const halfSize = size / 2;
        const xx = 0 - (rect?.width || 0) / 2;
        const yy = 0 - (rect?.height || 0) / 2;
        return <div className={styles.Particle} style={{animationDuration: `${size * 700}ms`,left: x - halfSize + xx / (300 / size), top: y - halfSize + yy / (300 / size), width: size, filter: `blur(${20 - size}px)`}} key={index} />
    }

    useEffect(() => {
        
        const particles: Particle[] = [];
        for (let i = 0; i < 100; i += 1) {
            particles.push({
                x: random(0, window.innerWidth),
                y: random(0, window.innerHeight),
                size: random(1, 30)
            });
        }
        setParticle(particles);
    
    }, [window.innerHeight, window.innerWidth]);

    return <>{particles.map(renderParticle)}</>;
}

const BackContent: React.FC<BackContentProps> = ({}) => {
    const translations = useTranslate();
    const {language} = useStore();
    
    const [refreshAnimation, setRefreshAnimation] = useState<boolean>(false);
    
    useEffect(() => {
        setRefreshAnimation(true);    
    }, [language]);
    
    useEffect(() => {
        if (!refreshAnimation) return;
        setRefreshAnimation(false);
    }, [refreshAnimation]);
    
    return <div className={styles.Container}>
        {/* <div className={styles.ParticlesContainer}> */}
            {/* <Particles /> */}
        {/* </div> */}
        <div className={styles.CenterContainer}>
            <p className={styles.Line}>{translations.get('Hello')},</p>
            {!refreshAnimation && <TypeAnimation
                className={styles.LineAnimation}
                sequence={[
                    translations.get('IM'),
                    0,
                    translations.get('IM2'),
                    2000,
                    translations.get('IM3'),
                    2000,
                ]}
                wrapper={"p"}
                speed={50}
                repeat={Infinity}
            />}
        </div>
    </div>
}

export default BackContent;