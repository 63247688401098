import React from 'react';
import styles from './AboutMe.module.scss';
import {useTranslate} from "../../../Common/Translate";
import Title from "../../../Common/Title/Title";
import Socials from "../../Socials/Socials";
import useStore, {Languages} from "../../../Store/Store";

interface AboutMeProps {
    
}

const AboutMe: React.FC<AboutMeProps> = ({}) => {
    const translations = useTranslate();
    const {language} = useStore();
    
    const CVs: Record<Languages, string> = {
        [Languages.EN]: 'CV-en.pdf',
        [Languages.PL]: 'CV-pl.pdf',
    }
    
    const downloadCV = () => {
        const link = document.createElement('a');
        link.href = CVs[language];
        link.download = 'CV.pdf';
        link.dispatchEvent(new MouseEvent('click'));
        link.remove();
    }
    
    return <div className={`${styles.Container} SubpageContainer`} id={'AboutMe'}>
        <div className={styles.HalfBox}>
            <Title>{translations.get('About me')}</Title>
            <div className={styles.TextBox}>
                <p dangerouslySetInnerHTML={{__html: translations.get('AboutMe Description')}}></p>
                <p className={styles.Email}><a href={'mailto:contact@bajkowski.dev'}>contact@bajkowski.dev</a></p>
                <Socials />
                <button className={styles.DownloadCVButton} onClick={downloadCV}>{translations.get('Download CV')}</button>
            </div>
        </div>
        <div className={styles.HalfBox}>
            <div className={styles.AvatarBox}>
                <img src={'/avatar.png'} alt={'avatar'} />
            </div>
        </div>
    </div>
}

export default AboutMe;